import {instance} from '../index';

export const promoCodesApi = {
  generatePromoCode () {
    return instance.get('promos/generate')
  },
  savePromoCode (data) {
    return instance.post('promos', data)
  },
  getActivePromoCodes () {
    return instance.get('promos?sort=createdon_desc&active=true');
  },
}
