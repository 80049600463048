<script>
import './PromoCodesGenerator.styl'
import {promoCodesApi} from '@/api/admin/promoCodes.js';
import {mapGetters} from 'vuex';
import PlaneCheckbox from '@/components/common/Checkbox/PlaneCheckbox.vue';
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import InfoPopup from '@/components/common/modals/InfoPopup';
import Spinner from '@/components/common/Spinner/Spinner.vue';

export default {
  name: 'promo-codes-generator',
  data: () => ({
    percent: null,
    amount: null,
    promoCode: '',
    attributedTo: '',
    promoCodes: [],
    tiers: [],
    trialDuration: null,
    selectedTrialPeriod: '',
    selectedTier: '',
    isOneTime: false,
    loading: false,
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'getPeriods',
      'getPeriodById',
      'getActiveEnrichedSubscriptionsPlans',
    ]),
  },
  async mounted () {
    try {
      await this.$store.dispatch('fetchEnrichedSubscriptionsPlans')
      await this.$store.dispatch('fetchPeriods')
    } catch (e) {
      console.error(e)
    }
  },
  async created () {
    await this.reloadPromoCodes();
    this.tiers = await this.getTiers();
  },
  methods: {
    copy (text) {
      navigator.clipboard.writeText(text);
    },
    getDisplayDate (dateString) {
      return dateString === null
        ? ''
        : `${new Date(dateString).toLocaleString('en-US')}`;
    },
    getDisplayTrial (promo) {
      if (promo.trialPeriodId) {
        return `${promo.trialDuration} ${this.getPeriodById(promo.trialPeriodId)?.name}`;
      }
    },
    async reloadPromoCodes () {
      await this.executeIntegration(async () => {
        try {
          const promoData = await promoCodesApi.getActivePromoCodes();
          this.promoCodes = promoData.data?.promos;
        } catch (e) {
          console.error(e)
        }
      })
    },
    async generatePromoCode () {
      await this.executeIntegration(async () => {
        try {
          const response = await promoCodesApi.generatePromoCode();

          if (response.data !== null) {
            this.promoCode = response.data;
          } else {
            console.log(response);
            this.showInfoDialog('Something went wrong');
          }
        } catch (e) {
          console.log(e)
          this.showInfoDialog('Something went wrong');
        }
      });
    },
    async savePromoCode () {
      if (!this.validateFields()) {
        return
      }

      const data = {
        promo: {
          name: this.attributedTo,
          code: this.promoCode,
          isOneTime: this.isOneTime,
          amount: this.amount,
          percent: this.percent,
          trialPeriodId: this.selectedTrialPeriod,
          trialDuration: this.trialDuration,
          subscriptionPlanTier: this.selectedTier,
        },
      }

      await this.executeIntegration(async () => {
        try {
          const response = await promoCodesApi.savePromoCode(data);

          if (response.data !== null) {
            await this.reloadPromoCodes();
            this.clearSpecs();
          } else {
            console.log(response);
            this.showInfoDialog('Something went wrong');
          }
        } catch (e) {
          console.log(e)
          this.showInfoDialog('Something went wrong');
        }
      })
    },
    validateFields () {
      if (!this.promoCode) {
        this.showInfoDialog('Promo code not entered');
        return false
      }
      if (!(this.percent || this.amount || (this.trialDuration && this.selectedTrialPeriod))) {
        this.showInfoDialog('Not entered required fields');
        return false;
      }
      return true;
    },
    clearSpecs (fromField) {
      switch (fromField) {
        case 'trial':
          this.percent = null
          this.amount = null
          break
        case 'percent':
          this.amount = null
          this.trialDuration = null
          this.selectedTrialPeriod = ''
          break
        case 'amount':
          this.percent = null
          this.trialDuration = null
          this.selectedTrialPeriod = ''
          break
        default:
          this.percent = null
          this.amount = null
          this.trialDuration = null
          this.selectedTrialPeriod = ''
          this.selectedTier = ''
      }
    },
    showInfoDialog (title) {
      this.$modal.show('InfoPopup', { title: title });
    },
    async executeIntegration (callback) {
      this.loading = true;

      try {
        await callback()
      } finally {
        this.loading = false;
      }
    },
    getTiers () {
      const plans = this.getActiveEnrichedSubscriptionsPlans;

      return plans.reduce((acc, current) => {
        if (!acc.find(item => item.id === current.tier)) {
          acc.push({id: current.tier, name: current.code})
        }

        return acc;
      }, []);
    },
    getTierName (tier) {
      return tier
        ? this.tiers.find(t => t.id === tier)?.name
        : null;
    },
  },
  components: {
    Spinner,
    PlaneCheckbox,
    InfoPopup,
    SvgIcon,
  },
}
</script>

<template>
  <div class="dashboard">
    <vue-scroll :ops="ops">
      <div id="generateContainer">
        <div id="title" class="title">Promo code generator</div>
        <div id="specificationContainer" class="spec">
          <div id="percentInput" class="custom-input" style="--prefix: '%'">
            <input class="field"
                   v-model="percent"
                   v-mask="'##'"
                   placeholder="Percent"
                   type="text"
                   @input="clearSpecs('percent')"
            />
          </div>
          <div id="amountInput" class="custom-input" style="--prefix: '$'">
            <input class="field"
                   v-model="amount"
                   v-mask="'####'"
                   placeholder="Amount"
                   type="text"
                   @input="clearSpecs('amount')"/>
          </div>
          <select class="selectPeriod"
                  :class="{ default: selectedTier === '' }"
                  style="margin-top: 25px"
                  v-model="selectedTier">
            <option class="defaultOption" value=''>Select tier...</option>
            <option
              class="option"
              v-for="tier in tiers"
              :key="tier.id"
              :value="tier.id">
              {{ tier.name }}
            </option>
          </select>
          <select class="selectPeriod"
                  :class="{ default: selectedTrialPeriod === '' }"
                  style="margin-top: 25px"
                  @change="clearSpecs('trial')"
                  v-model="selectedTrialPeriod">
            <option class="defaultOption" value=''>Select trial period...</option>
            <option
              class="option"
              v-for="period in this.getPeriods()"
              :key="period.id"
              :value="period.id">
              {{ period.name }}
            </option>
          </select>
          <div id="durationInput" style="margin-top: 25px">
            <input id="trialDurationInput"
                   class="field"
                   v-model="trialDuration"
                   v-mask="'###'"
                   type="text"
                   placeholder="Trial duration"
                   @input="clearSpecs('trial')"/>
          </div>
        </div>
        <div id="isOneTimeCheckBox"
             class="oneTimeCheckbox"
             style="margin-left: 20px; margin-top: 25px;">
          <PlaneCheckbox
            :isChecked.sync="isOneTime"
            label="SINGLE USE"
          />
        </div>
        <div id="attributedToContainer" class="promo" style="margin-top: 25px">
          <input id="attributedToContainer"
                 class="field"
                 v-model="attributedTo"
                 placeholder="Attributed to"
                 type="text"/>
        </div>
        <div id="promoContainer" class="promo" style="margin-top: 25px">
          <input id="promoInput"
                 class="field promo"
                 v-model="promoCode"
                 placeholder="Code"
                 type="text"/>
          <button id="copyPromoButton"
                  class="buttonCopy input"
                  @click.prevent="copy(promoCode)">
              <span>
                  <SvgIcon name="copy"/>
              </span>
          </button>
        </div>
        <div id="buttonsContainer" class="promo" style="margin-top: 25px">
          <button id="generateButton"
                  class="promoButton"
                  style="margin-right: 10px"
                  @click.prevent="generatePromoCode">
            <span class="text">Generate</span>
          </button>
          <button id="saveButton"
                  class="promoButton"
                  style="margin: 0 10px"
                  @click.prevent="savePromoCode">
            <span class="text">Save code</span>
          </button>
        </div>
      </div>
      <table id="promoTable"
             class="table"
             style="margin-top: 25px">
        <thead>
        <div
          class="title"
          style="margin-bottom: 25px">Active promo code</div>
        <tr class="table-header">
          <th class="table-cell">Code</th>
          <th class="table-cell">Attributed to</th>
          <th class="table-cell">Tier</th>
          <th class="table-cell">Amount</th>
          <th class="table-cell">Percent</th>
          <th class="table-cell">Trial</th>
          <th class="table-cell">Created date</th>
          <th class="table-cell">Single use</th>
        </tr>
        </thead>
        <tbody class="table-body">
        <tr v-for="(item, index) in promoCodes" :key="index" class="table-row">
          <td class="table-cell">
            <span class="mobile-label">CODE:</span>
            <button id="copyPromoButton" class="buttonCopy" @click.prevent="copy(item.code)">
                <span>
                  <SvgIcon name="copy"/>
                </span>
            </button>
            <span style="text-transform: none; font-weight: bolder" @click.prevent="copy(item.code)">{{ item.code }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Attributed to:</span>
            <span>{{ item.name }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Tier:</span>
            <span>{{ getTierName(item.subscriptionPlanTier) }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Amount:</span>
            <span>{{ item.amount ? `$${item.amount}` : "" }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Percent:</span>
            <span>{{ item.percent ? `${item.percent}%` : "" }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Trial:</span>
            <span>{{ getDisplayTrial(item) }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Created date:</span>
            <span>{{ getDisplayDate(item.createdOn) }}</span>
          </td>
          <td class="table-cell">
            <span class="mobile-label">Single use:</span>
            <PlaneCheckbox :isChecked="item.isOneTime" :isDisabled="true"/>
          </td>
        </tr>
        </tbody>
      </table>
      <InfoPopup/>
    </vue-scroll>
    <div v-if="loading" class="spinner-overlay">
      <Spinner/>
    </div>
  </div>
</template>
